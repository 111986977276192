<template>
  <v-card outlined>
    <v-card-text>
      <!-- <v-expansion-panels flat :value="myPanel" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header>
          {{ $t("searchPanel") }}
        </v-expansion-panel-header>
        <v-expansion-panel-content> -->
      <v-row>
        <v-col cols="12" md="3" sm="6">
          <v-combobox
            v-model="patientName"
            :items="patients"
            clearable
            :label="$t('patients.patientName')"
            item-text="patientDisplayName"
            item-value="patientDisplayName"
            :return-object="false"
            hide-details
            outlined
            dense
          >
          </v-combobox>
        </v-col>
        <v-col cols="12" md="3" sm="6">
          <v-autocomplete
            v-model="patientCategory"
            :items="categories"
            clearable
            :label="$t('patients.patientCategory')"
            item-text="name"
            item-value="guid"
            :return-object="false"
            hide-details
            outlined
            dense
            multiple
          >
          </v-autocomplete>
        </v-col>

        <v-col cols="12" md="2" sm="4">
          <v-dialog
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fromDate"
                hide-details
                outlined
                dense
                :label="$t('fromDate')"
                persistent-hint
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fromDate"
              no-title
              @input="menu1 = false"
            ></v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="12" md="2" sm="4">
          <v-dialog
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                hide-details
                outlined
                dense
                v-model="toDate"
                :label="$t('toDate')"
                persistent-hint
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="toDate"
              no-title
              @input="menu2 = false"
            ></v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="12" md="2" sm="4">
          <v-btn elevation="0" block color="primary" @click="filterChange()">
            {{ $t("refresh") }}
            <v-icon> mdi-refresh </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row> </v-row>
      <!-- </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels> -->
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["patients", "panel"],
  data() {
    return {
      //panel: [0],
      menu1: null,
      menu2: null,
      patientName: "",
      patientCategory: "",
      fromDate: "",
      toDate: "",
      status: null,
      filterItem: {},
      categories: [],
    };
  },
  created() {
    this.fromDate = this.$moment().format("YYYY-MM-DD");
    this.toDate = this.$moment().format("YYYY-MM-DD");
    this.getPatientCategories();
    //if (this.panel) this.myPanel.push(this.panel);
  },
  watch: {
    patientName() {
      this.filterChange();
    },
  },
  computed: {
    myPanel() {
      return this.panel ? [0] : [];
    },
  },
  methods: {
    filterChange() {
      var filterItem = {
        patientName: this.patientName,
        patientCategory: this.patientCategory,
        fromDate: this.fromDate,
        toDate: this.toDate,
      };
      this.$emit("filterChange", filterItem);
    },
    getPatientCategories() {
      this.$axios
        .get("Category")
        .then((response) => {
          this.categories = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
