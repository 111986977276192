<template>
  <v-autocomplete
    :readonly="editedIndex != -1"
    persistent-hint
    v-model="patientGuid"
    :rules="[rules.required]"
    :label="$t('patients.patientName')"
    item-text="patientDisplayName"
    item-value="guid"
    :items="filteredItems"
    @focus="onFocus"
  >
    <template v-slot:append-item>
      <div v-intersect="endIntersect" />
    </template>
    <template v-slot:prepend-item>
      <v-text-field
        v-model="searchPatient"
        ref="autocomplete"
        @input="patientKeyUp"
        :label="$t('search')"
      />
    </template>

    <template v-slot:item="{ item }">
      <div v-html="highlightMatch(item.patientDisplayName)"></div>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: ["editedIndex"],
  data() {
    return {
      searchPatient: "",
      patientGuid: "",
      timeout: 0,
      patientPage: 0,
      patientsList: [],
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
      },
    };
  },

  watch: {
    patientGuid(val) {
      this.$emit("patientChange", val);
      //   console.log(val);
    },
  },
  computed: {
    filteredItems() {
      // Filter the list of patients based on the search query

      if (this.searchPatient) {
        return this.patientsList.filter((patient) => {
          return patient.patientDisplayName.includes(this.searchPatient);
        });
      } else {
        return this.patientsList;
      }
    },
  },

  methods: {
    onFocus() {
      // Do something when the autocomplete is focused
      setTimeout(() => {
        this.$refs["autocomplete"].focus();
      }, 200);
    },
    highlightMatch(text) {
      // Escape special characters in the search query

      if (this.searchPatient && this.searchPatient != "") {
        const escapedFilter = this.searchPatient.replace(
          /[.*+?^${}()|[\]\\]/g,
          "\\$&"
        );
        // Use a regular expression to highlight the matched text
        const highlightedText = text.replace(
          new RegExp(escapedFilter, "gi"),
          "<mark style='background-color: #d1d1d1; border-radius: 2px;'>$&</mark>"
        );
        return highlightedText;
      }
      return text;
    },
    endIntersect(_entries, _observer, isIntersecting) {
      if (isIntersecting == true) {
        this.getPatientsByPage();
      }
    },
    patientKeyUp() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      // Set a new timeout to call the API after 2 seconds
      this.timeout = setTimeout(() => {
        this.getPatientsByPage();
      }, 1500);
    },
    getPatientsByPage() {
      this.$axios
        .get(
          "Patient/GetByPages?skip=" +
            this.patientPage +
            "&filter=" +
            this.searchPatient
        )
        .then((response) => {
          if (response.data.data.length > 0) {
            this.patientPage += response.data.data.length ?? 0;
            this.patientsList = [...this.patientsList, ...response.data.data];
          }
        });
    },
  },
};
</script>

<style></style>
