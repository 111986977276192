<template>
  <v-container fluid>
    <patient-filter
      :patients="patients"
      :panel="true"
      @filterChange="filterChange"
    ></patient-filter>
    <v-card outlined class="pa-5 mt-3">
      <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-0 py-5' : ''">
        <v-toolbar flat>
          <v-row no-gutters style="max-width: 100% !important">
            <v-col>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                outlined
                dense
                class="shrink search"
                :class="$vuetify.breakpoint.xsOnly ? 'seachXs' : ''"
                :placeholder="$t('search')"
                :label="$t('search')"
                single-line
                hide-details
              />
            </v-col>

            <v-col cols="auto">
              <div class="float-end d-flex">
                <v-select
                  v-model="filterStatus"
                  hide-details
                  clearable
                  outlined
                  class="shrink"
                  dense
                  :items="status"
                  :placeholder="$t('appointments.appointmentStatus')"
                  item-text="text"
                  item-value="value"
                  @change="
                    () => {
                      filterItem.status = filterStatus;
                      refreshTable();
                    }
                  "
                ></v-select>
                <v-btn
                  style="margin-top: 2px"
                  :v-if="$store.getters.isInRole(20)"
                  color="primary"
                  elevation="0"
                  class="mx-1"
                  @click="dialog = true"
                >
                  {{ $t("add") }}

                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </div>
            </v-col>
            <!-- <v-col cols="12" md="2" align-end> </v-col> -->
          </v-row>
        </v-toolbar>
      </v-card-text>

      <data-table-component
        :headers="headers"
        :items="appointments"
        :search="search"
        :loading="loading"
      />

      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-toolbar color="primary darken-1" dark>
              <v-card-title>
                <span class="">{{
                  (editedIndex === -1 ? $t("add") : $t("edit")) +
                  " " +
                  $t("appointments.appointment")
                }}</span>
              </v-card-title>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="editedItem.doctorGuid"
                      :rules="rules"
                      :items="doctors"
                      :label="$t('doctors.doctorName')"
                      required
                      item-text="doctorName"
                      item-value="guid"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <patient-select
                      @patientChange="patientChange"
                      :editedIndex="editedIndex"
                    />
                    <!-- <v-autocomplete
                                v-model="editedItem.patientGuid"
                                :rules="rules"
                                :items="patients"
                                :label="$t('patients.patientName')"
                                required
                                item-text="patientDisplayName"
                                item-value="guid"
                              >
                                <template v-slot:append-item>
                                  <div v-intersect="endIntersect" />
                                </template>
                              </v-autocomplete> -->

                    <!-- <DxAutocomplete
                                :data-source="clientsStore"
                                v-model="editedItem.patientGuid"
                                :min-search-length="2"
                                :search-timeout="500"
                                :placeholder="$t('patients.patientName')"
                                @value-changed="updateEmployeeInfo"
                                value-expr="patientDisplayName"
                                display-expr="patientDisplayName"
                                stylingMode="underlined"
                              /> -->
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-datetime-picker
                      :label="$t('appointments.appointmentDate')"
                      v-model="editedItem.appointmentDate"
                      dateFormat="yyyy/MM/dd"
                      timeFormat="hh:mm aa"
                      :okText="$t('ok')"
                      :clearText="$t('cancel')"
                    >
                      <template v-slot:dateIcon>
                        <v-icon> mdi-calendar </v-icon>
                      </template>
                      <template v-slot:timeIcon>
                        <v-icon> mdi-clock </v-icon>
                      </template>
                    </v-datetime-picker>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="editedItem.note"
                      :label="$t('notes')"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="green darken-1"
                class="white--text"
                @click="save"
                :disabled="!valid"
                :min-width="100"
              >
                <v-icon>mdi-content-save-outline</v-icon>
                {{ $t("save") }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="close">
                {{ $t("cancel") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <confirm-dialog
        :openDialog="dialogDelete"
        :onClicked="deleteItemConfirm"
        :onClose="closeDelete"
      ></confirm-dialog>
    </v-card>
  </v-container>
</template>
<script>
import PatientFilter from "@/components/PatientFilter.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import PatientSelect from "@/components/PatientSelect.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";

import "devextreme/dist/css/dx.light.css";

export default {
  components: {
    PatientFilter,
    ConfirmDialog,
    PatientSelect,
    DataTableComponent,
  },
  data() {
    return {
      menu: null,
      valid: true,
      loading: true,
      dialog: false,
      dialogDelete: false,
      dialogStatus: false,
      editedIndex: -1,
      search: "",
      filterStatus: null,

      status: [
        {
          value: 0,
          text: this.$t("patients.waitTab"),
          icon: "mdi-clock",
          color: "yellow darken-3 white--text",
        },
        {
          value: 1,
          text: this.$t("patients.treatmentTab"),
          icon: "mdi-heart",
          color: "light-blue darken-1 white--text",
        },
        {
          value: 2,
          text: this.$t("patients.cancelTab"),
          icon: "mdi-close-thick",
          color: "red  darken-1 white--text",
        },
        {
          value: 3,
          text: this.$t("patients.doneTab"),
          icon: "mdi-check-bold",
          color: "green darken-1 white--text",
        },
      ],
      appointments: [],
      doctors: [],
      patients: [],
      patientPage: 0,
      filterItem: {
        patientName: "",
        fromDate: this.$moment().format("YYYY-MM-DD"),
        toDate: this.$moment().format("YYYY-MM-DD"),
        status: null,
      },
      editedItem: {
        doctorGuid: "",
        patientGuid: "",
        appointmentDate: new Date(),
        appointmentStatus: 0,
        note: "",
      },
      defaultItem: {
        //doctorGuid: "00000000-0000-0000-0000-000000000000",
        doctorGuid: "",
        patientGuid: "",
        appointmentDate: new Date(),
        appointmentStatus: 0,
        note: "",
      },
      rules: [(value) => !!value || this.$t("ThisFieldIsRequired")],
    };
  },
  created() {
    this.loading = true;
    this.refreshTable();
    //this.getPatientsByPage();
  },
  computed: {
    headers() {
      var list = [
        { text: this.$t("doctors.doctorName"), value: "doctorName" },
        { text: this.$t("patients.patientName"), value: "patientName" },
        {
          text: this.$t("appointments.appointmentDate"),
          value: "appointmentDate",
          type: "date",
          format: "YYYY/MM/DD hh:mm A",
        },
        {
          text: this.$t("appointments.appointmentStatus"),
          value: "appointmentStatus",
          type: "select",
          chip: true,
          items: this.status,
        },
        { text: this.$t("notes"), value: "note" },
      ];

      var actions = {
        text: "",
        value: "actions",
        templates: [],
      };

      if (this.$store.getters.isInRole(21)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-pencil",
          text: this.$t("edit"),
          click: this.editItem,
        });
      }
      if (this.$store.getters.isInRole(22)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-delete",
          text: this.$t("delete"),
          click: this.deleteItem,
          color: "red darken-2",
        });
      }
      list.push(actions);
      return list;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    pageLength(val) {
      if (this.page > val) this.page = val;
    },
  },

  methods: {
    patientChange(val) {
      if (val) {
        this.editedItem.patientGuid = val;
      }
    },

    getText(i) {
      var result = this.status.filter((obj) => {
        return obj.value == i;
      });
      return result[0].text;
    },
    editItem(item) {
      this.editedIndex = this.appointments.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.appointmentDate = new Date(
        this.editedItem.appointmentDate
      );

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.appointments.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    changeItemStatus(item) {
      this.editedIndex = this.appointments.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogStatus = true;
    },

    deleteItemConfirm() {
      var deleteItem = this.appointments[this.editedIndex];

      this.$axios
        .delete("Appointment/Delete?guid=" + deleteItem.guid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }
          this.refreshTable();
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeStatus() {
      this.dialogStatus = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    filterChange(filterItem) {
      this.filterItem = filterItem;
      this.filterItem.status = this.filterStatus;
      this.refreshTable();
    },

    refreshTable() {
      this.$axios
        .get("Appointment/Get?filter=" + JSON.stringify(this.filterItem))
        .then((response) => {
          this.appointments = response.data.data;

          if (this.isInRole("6")) {
            this.$axios.get("Doctor/GetForAppointment").then((response) => {
              this.doctors = response.data.data;
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    save() {
      var val = this.$refs.form.validate();
      if (val) {
        if (this.editedIndex > -1) {
          var updatedItem = this.appointments[this.editedIndex];

          updatedItem.doctorGuid = this.editedItem.doctorGuid;
          updatedItem.patientGuid = this.editedItem.patientGuid;
          updatedItem.appointmentDate = JSON.parse(
            JSON.stringify(this.editedItem.appointmentDate.toLocaleString())
          );
          updatedItem.appointmentStatus = this.editedItem.appointmentStatus;
          updatedItem.note = this.editedItem.note;

          this.$axios
            .post("Appointment/Update", updatedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable();
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              console.log(e);
            });
          Object.assign(this.appointments[this.editedIndex], this.editedItem);
        } else {
          var date = JSON.parse(
            JSON.stringify(this.editedItem.appointmentDate.toLocaleString())
          );
          var data = {
            doctorGuid: this.editedItem.doctorGuid,
            patientGuid: this.editedItem.patientGuid,
            appointmentDate: date,
            appointmentStatus: this.editedItem.appointmentStatus,
            note: this.editedItem.note,
          };

          this.$axios
            .post("Appointment/Add", data)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable();
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              console.log(e);
            })
            .finally(() => {
              this.editedItem.appointmentDate = new Date();
            });
        }
      }
    },
  },
};
</script>

<style scoped></style>
